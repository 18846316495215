import React, { useState, useEffect } from "react";
import { URL, ImgURL } from "../url/api";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/swiper.min.css";
import { Link, useLocation } from "react-router-dom";
import ActivityIndicator from "./ActivityIndicator";
import walnut from "../asset/pngImage/walnut.png";
import ZayaAndroid from "../asset/jpgImage/ZayaDryFruitsAndroid.jpg";
import ZayaIOS from "../asset/jpgImage/zayadryfruitsIOS.jpg";

const ImageSlider = ({ images }) => {
  const { pathname } = useLocation();
  const [bannerData, setBannerData] = useState([]);
  const [data, setData] = useState([]);
  const [subCataegories, setSubCataegories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        // Make both API calls concurrently
        const [dashboardResponse, subCategoriesResponse] = await Promise.all([
          fetch(`${URL}/getDashboard`, { method: "GET", redirect: "follow" }),
          fetch(`${URL}/getSubCategories`, {
            method: "GET",
            redirect: "follow",
          }),
        ]);

        // Process the response from the first API call (getDashboard)
        const dashboardResult = await dashboardResponse.text();
        const dashboardData = JSON.parse(dashboardResult);
        const sliderArray = dashboardData.data.sliders.map((slide) => {
          return {
            image: slide?.image,
            redirect_url: slide?.redirect_url, // Ensure this property exists in your response
          };
        });

        setBannerData(sliderArray);
        setData(dashboardData.data.categories);

        // Process the response from the second API call (getFeaturedProducts)
        const subCategoriesResult = await subCategoriesResponse.text();
        const subCategoriesData = JSON.parse(subCategoriesResult);
        setSubCataegories(subCategoriesData.data);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleImageError = (event) => {
    event.target.src = walnut; // Use the logo variable from your imports
  };
  const handleBannerClick = (url) => {
    if (url) {
      window.location.href = url;
    }
  };

  if (loading) {
    return <ActivityIndicator />;
  }
  return (
    <div className="container-fluid">
      {/* This is banner code */}
      <div
        id="carouselExampleAutoplaying"
        className="carousel slide"
        data-bs-ride="carousel"
        data-bs-interval="2000"
      >
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <div className="carousel-inner">
          {bannerData?.map((imageUrl, index) => {
            return (
              <div
                className={`carousel-item ${index === 0 ? "active" : ""}`}
                key={index}
                onClick={() => handleBannerClick(imageUrl?.redirect_url)}
                style={{
                  cursor: imageUrl.redirect_url ? "pointer" : "default",
                }}
              >
                <img
                  src={`${ImgURL}${imageUrl?.image}`}
                  alt={`Banner ${index + 1}`}
                  loading="lazy"
                  className="d-block w-100 bannerheight"
                />
              </div>
            );
          })}
        </div>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      <h1
        className="textColor"
        style={{
          fontSize: 20,
          margin: 12,
          // marginBottom: 0,
          textAlign: "center",
          fontWeight: "500",
        }}
      >
        The Best DryFruits, Nuts, Pulses and Masale Online Shop
      </h1>
      {/* This is Categories code  */}
      <div className="slider-button">
        <button className="swiper-button-next" onClick={handlePrev}>
          <i className="fa-solid fa-angles-left"></i>
        </button>{" "}
        <Swiper
          onSwiper={setSwiper}
          slidesPerView={1}
          pagination={{ clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
        >
          {data.map((item) => {
            let name = item.name;
            const product = name.replace(/[\s()]+/g, "-").replace(/-+$/, "");
            let subCategoryName = item?.subcategory[0]?.name;
            const subCategoryProduct = subCategoryName
              ? subCategoryName.replace(/[\s()]+/g, "-").replace(/-+$/, "")
              : "";
            return (
              <SwiperSlide key={item.id}>
                <Link
                  className="nav-link"
                  aria-current="page"
                  to={`/category/${item.id}/${product}/${item?.subcategory[0]?.id}/${subCategoryProduct}`}
                >
                  <img
                    key={item.id}
                    loading="lazy"
                    className="image-hover"
                    src={`${ImgURL}${item.img}`}
                    alt={item.name + "Image"}
                    onError={handleImageError}
                    style={{
                      height: 170,
                      width: "100%",
                      margin: "0%",
                      resizeMode: "contain",
                      borderRadius: 8,
                      padding: 1,
                    }}
                  />
                  <div style={{ textAlign: "center", margin: 8 }}>
                    <h6>{item.name}</h6>
                  </div>
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <button className="swiper-button-prev" onClick={handleNext}>
          <i className="fa-solid fa-angles-right"></i>
        </button>
      </div>
      <div className="row d-flex" style={{ justifyContent: "center" }}>
        <div
          className="col-md-6"
          onClick={() =>
            (window.location.href =
              "https://play.google.com/store/apps/details?id=com.beaconcoders.zayadryfruits")
          }
        >
          <img
            src={ZayaAndroid}
            alt="android"
            loading="lazy"
            className="imageAdd" // Add cursor pointer style
          />
        </div>
        <div
          className="col-md-6"
          onClick={() =>
            (window.location.href =
              "https://apps.apple.com/in/app/zaya-dryfruits/id6478808117")
          }
        >
          <img
            src={ZayaIOS}
            alt="ios"
            loading="lazy"
            className="imageAdd" // Add cursor pointer style
          />
        </div>
      </div>
      {/* This is subcategories code */}
      <div className="row" style={{ justifyContent: "center" }}>
        {subCataegories.map((subCategory) => {
          let name = subCategory.name;
          const product = name.replace(/[\s()]+/g, "-").replace(/-+$/, "");
          let subCategoryName = subCategory?.category?.name;
          return (
            <div
              className="col-md-1 mobileviewcategory p-1 m-1 mx-2"
              key={subCategory.id}
            >
              <Link
                className="nav-link"
                aria-current="page"
                to={`/category/${subCategory?.category?.id}/${subCategoryName}/${subCategory?.id}/${product}`}
              >
                <div
                  className="card"
                  style={{
                    margin: 10,
                    resizeMode: "contain",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 10,
                  }}
                >
                  <img
                    key={subCategory.id}
                    loading="lazy"
                    className="image-hover"
                    src={`${ImgURL}${subCategory.img}`}
                    alt={subCategory.name + "Image"}
                    onError={handleImageError}
                    style={{
                      borderRadius: 10,
                      width: "84%",
                      margin: "8% ",
                      resizeMode: "contain",
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    WebkitLineClamp: 1,
                    textAlign: "center",
                  }}
                >
                  <p
                    className="fw-bold"
                    style={{
                      fontSize: 12,
                      marginBottom: 0,
                    }}
                  >
                    {subCategory.name}
                  </p>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ImageSlider;
