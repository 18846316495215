import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import "swiper/swiper.min.css";

export default function CategoriesHeader({ data, onSelectCategory }) {
  const [swiper, setSwiper] = useState(null);

  const handleNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };
  return (
    <div className="categories-container">
      <button className="swiper-button-next1" onClick={handlePrev}>
        <i className="fa-solid fa-angles-left"></i>
      </button>
      <Swiper
        onSwiper={setSwiper}
        slidesPerView={1}
        pagination={{ clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
      >
        {data.map((item) => {
          let name = item.name;
          const product = name.replace(/[\s()]+/g, "-").replace(/-+$/, "");
          let subCategoryName = item?.subcategory[0]?.name;
          const subCategoryProduct = subCategoryName
            ? subCategoryName.replace(/[\s()]+/g, "-").replace(/-+$/, "")
            : "";
          return (
            <SwiperSlide key={item.id} className="category-item">
              <Link
                className="nav-link swiper-slide1"
                onClick={() => {
                  onSelectCategory(item);
                }}
                to={`/category/${item.id}/${product}/${item?.subcategory[0]?.id}/${subCategoryProduct}`}
              >
                <span>{item.name}</span>
              </Link>
            </SwiperSlide>
          );
        })}
      </Swiper>

      <button className="swiper-button-prev1" onClick={handleNext}>
        <i className="fa-solid fa-angles-right"></i>
      </button>
    </div>
  );
}
