import React from "react";

const ActivityIndicator = () => {
  return (
    <div
      style={{
        backgroundColor: "white",
        height: "100%",
        width: "100%",
        padding: "100%",
      }}
    >
      <div
        className="spinner-border"
        style={{ position: "fixed", top: "50%", left: "50%", color: "#825d37" }}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default ActivityIndicator;
