import React from "react";
import Cookies from "js-cookie";
import { URL } from "../url/api";
import { toast } from "react-toastify";
const ProfileList = ({ onSelectSubcategory }) => {
  const showToast = (title) => {
    toast.success(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        logoutApp(token);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };

  const logoutApp = (Token) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("os", "WEB");

    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${URL}/logout`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        if (newData.error == false) {
          window.location.replace("/login");
          Cookies.remove("user_session");
        }
        showToast(newData.msg);
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <div>
      {/* <div
        className="card my-3"
        style={{ backgroundColor: "white", padding: 20 }}
      >
        Profile
      </div> */}
      <div
        role="button"
        className="col card"
        style={{
          padding: 10,
          marginTop: 4,
          marginBottom: 4,
          flexDirection: "row",
          alignItems: "center",
        }}
        onClick={() => onSelectSubcategory("0")}
      >
        <i className="fa-solid fa-user mx-3"></i>Profile
      </div>
      <div
        role="button"
        className="col card"
        style={{
          padding: 10,
          marginTop: 4,
          marginBottom: 4,
          flexDirection: "row",
          alignItems: "center",
        }}
        onClick={() => onSelectSubcategory("1")}
      >
        <i className="fa fa-map-location-dot mx-3"></i>My Address
      </div>
      <div
        role="button"
        className="col card"
        style={{
          padding: 10,
          marginTop: 4,
          marginBottom: 4,
          flexDirection: "row",
          alignItems: "center",
        }}
        onClick={() => onSelectSubcategory()}
      >
        <i className="fa-solid fa-wallet mx-3"></i>
        My Orders
      </div>
      {/* <div
        className="col card"
        style={{
          padding: 10,
          marginTop: 4,
          marginBottom: 4,
          flexDirection: "row",
          alignItems: "center",
        }}
        onClick={() => onSelectSubcategory("3")}
      >
        <i className="fa-solid fa-wallet mx-3"></i>My Wallet
      </div> */}
      <div
        role="button"
        className="col card"
        style={{
          padding: 10,
          marginTop: 4,
          marginBottom: 4,
          flexDirection: "row",
          alignItems: "center",
        }}
        onClick={() => checkLogin()}
      >
        <i className="fa-solid fa-right-from-bracket mx-3"></i>Logout
      </div>
    </div>
  );
};

export default ProfileList;
