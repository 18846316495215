import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";

const ShippingPolicy = () => {
  const sectionRef = useRef(null);
  const currentPageUrl = window.location.href;
  useEffect(() => {
    if (window.location.hash === "#shipping&refund-policy") {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <div
      className="container"
      style={{ marginTop: 65, textAlign: "justify" }}
      ref={sectionRef}
    >
      <Helmet>
        <title>Shipping & Refund Policy</title>
        <meta property="og:title" content={"Shipping & Refund Policy"} />
        <meta property="og:url" content={currentPageUrl} />
        <meta property="og:site_name" content="Zaya Dryfruits" />
      </Helmet>
      <br />
      {/* <h4 className="textColor text-center">Shipping & Refund Policy</h4>
      <p className="textColor">Last updated June 24, 2021</p>
      <hr />
      <p>
        Thank you for your purchase. We hope you are happy with your purchase.
        However, if you are not completely satisfied with your purchase for any
        reason, you may return it to us for a full refund, store credit, or an
        exchange . Please see below for more information on our return policy.
      </p>

      <h4 className="textColor">SHIPPING</h4>
      <p>
        Accepted order will be delivered in time of 1 hours to 48 hours (depends
        on the distance from our stores) Free shipping to Delhi, Gurugram and
        Noida area. Except these locations, shipping charges will be applicable
        as per actual. All Deliveries will be processed between from 08:00 Am to
        9:00 PM on all days .
      </p>
      <h4 className="textColor">RETURNS</h4>
      <p>
        All returns must be postmarked within seven (7) days of the purchase
        date. All returned items must be in new and unused condition, with all
        original tags and labels attached.
      </p>
      <h4 className="textColor">RETURN PROCESS</h4>
      <p>
        To return an item, please email customer service at
        info@zayadryfruits.com to obtain a Return Merchandise Authorization
        (RMA) number. After receiving a RMA number, place the item securely in
        its original packaging and include your proof of purchase, and mail your
        return to the following address:
      </p>
      <li>
        26, Central Market, Lajpat Nagar II, Lajpat Nagar, New Delhi Delhi
        110024 Attn: Returns RMA #
      </li>
      <li>
        26, Central Market, Lajpat Nagar II, Lajpat Nagar New Delhi, Delhi
        110024 India
      </li>
      <br />
      <p>Return shipping charges will be paid or reimbursed by us.</p>

      <h4 className="textColor">REFUNDS</h4>
      <p>
        After receiving your return and inspecting the condition of your item,
        we will process your return or exchange . Please allow at least seven
        (7) days from the receipt of your item to process your return or
        exchange . Refunds may take 1-2 billing cycles to appear on your credit
        card statement, depending on your credit card company. We will notify
        you by email when your return has been processed.
      </p>
      <h4 className="textColor">EXCEPTIONS</h4>
      <p>
        For defective or damaged products, please contact us at the contact
        details below to arrange a refund or exchange.
      </p>
      <p className="textColor">Please Note</p>
      <li> Sale items are FINAL SALE and cannot be returned.</li>
      <br />
      <h4 className="textColor">QUESTIONS</h4>
      <p>
        If you have any questions concerning our return policy, please contact
        us at: +(91) 99583 44409 info@zayadryfruits.com
      </p>
      <h4 className="textColor">PAYMENT TERMS</h4>
      <p>
        If Order failed and payment deducted from your account, it will be
        automatically refunded to your account. Payment for refund requested
        will be processed in 4-5 working days after recieving returned product
      </p> */}
      <div class="shippingcontainer">
        <h4 className="textColor text-center">SHIPPING & REFUND POLICY</h4>
        <p className="textColor">Last updated November 13, 2024</p>
        <hr />
        <p>
          Thank you for your purchase. We hope you are happy with your purchase.
          However, if you are not completely satisfied with your purchase for
          any reason, you may return it to us for a full refund, store credit,
          or an exchange . Please see below for more information on our return
          policy.
        </p>
        <h2>Purchase Policy</h2>
        <div class="policy-section">
          <p>
            Thank you for shopping with us! By placing an order, you agree to
            the following terms and conditions:
          </p>
          <h3>Product Availability</h3>
          <p>
            All dry fruits listed on our website are subject to availability. In
            the event that a product is out of stock, we will notify you and
            offer alternatives or provide a refund in case prepaid.
          </p>

          <h3>Order Confirmation</h3>
          <p>
            Once your order is placed, you will receive confirmation. If you do
            not receive contact our customer service team.
          </p>

          <h3>Payment</h3>
          <p>
            We accept various payment methods, including credit/debit cards and
            secure online payment platforms. Payment must be made at the time of
            order
            <br />
            <br />
            Prepaid Payment for Shipping Outside Delhi/NCR
            <br />
            <br />
            Orders outside Delhi/NCR
            <br />
            <br />
            Please note that for all orders being shipped outside Delhi/NCR,
            prepaid payment is required at the time of purchase. We do not offer
            cash on delivery (COD) for shipments outside of the Delhi/NCR
            region.
            <br />
            <br />
            During checkout, you will be asked to make the full payment for your
            order, including shipping fees, before your order is processed and
            dispatched.
            <br />
            <br />
            If Order failed and payment deducted from your account, it will be
            automatically refunded to your account. Payment for refund requested
            will be processed in 4-5 working days after recieving returned
            product. Thank you for understanding!
          </p>

          <h3>Pricing</h3>
          <p>
            Prices are listed in the local currency and are inclusive of
            applicable taxes unless stated otherwise. We reserve the right to
            update pricing as necessary without prior notice.
          </p>
        </div>
        <h2>Shipping Policy</h2>
        <div class="policy-section">
          <p>
            We strive to deliver your dry fruit products as quickly and safely
            as possible. Please review the following details regarding shipping:
          </p>
          <h3>Shipping Areas</h3>
          <p>
            We ship to most regions. Please check your delivery address for
            service availability.
          </p>

          <h3>Processing Time</h3>
          <p>
            Orders are processed and shipped within 1-3 business days for
            Delhi/NCR and 5-7 days or more for outside Delhi/NCR
            <br /> Orders placed on weekends or holidays may be processed the
            next business day.
          </p>

          <h3>Shipping Fees</h3>
          <p>
            Shipping costs vary depending on the delivery location, weight of
            the products, and selected shipping method. Shipping fees will be
            calculated at checkout, which is estimated price.
          </p>

          <h3>Shipping Cost Adjustment</h3>
          <p>
            Shipping costs are estimates and may vary based on your location or
            carrier. If there’s any change, we will contact you by phone to
            confirm the updated cost before processing your order.
          </p>

          <h3>Delivery Time</h3>
          <p>
            Estimated delivery time typically ranges between 3-7 business days,
            depending on your location. Please note that delivery times may vary
            due to factors such as weather conditions or courier delays.
          </p>

          <h3>Tracking</h3>
          <p>
            Once your order has shipped, you will receive a tracking number to
            monitor the delivery status of your package.
          </p>

          <h3>Shipping Delays</h3>
          <p>
            While we make every effort to ensure timely delivery, we are not
            responsible for delays caused by external factors such as customs,
            natural disasters, or third-party carriers or inside delays
          </p>
        </div>
        <h2>Refund Policy</h2>
        <div class="policy-section">
          <p>
            Customer satisfaction is important to us. If you're not fully
            satisfied with your dry fruit purchase, please review our refund
            process:
          </p>
          <h3>Eligibility for Refunds</h3>
          <p>
            You may request a refund within 7 days of receiving your order if
            the product is damaged, defective, or incorrect.
            <br />
            <br /> Refunds will not be issued for items that have been opened or
            consumed. <br />
            <br /> Refunds may take 1-2 billing cycles to appear on your credit
            card statement, depending on your credit card company. We will
            notify you by email when your return has been processed.
          </p>

          <h3>How to Request a Refund</h3>
          <p>
            To initiate a refund, please contact our customer service team with
            your order number and details of the issue. We may request photos or
            other documentation to process your claim. Also you can mail for
            same.
            <br />
            Once the issue is verified, we will issue a refund within 5-7
            business days.
          </p>
          <h2>Return Shipping</h2>
          <p>
            All returns must be postmarked within seven (7) days of the purchase
            date. All returned items must be in new and unused condition, with
            all original tags and labels attached.
            <br />
            <br />
            In case of damaged or incorrect items, we will cover the return
            shipping costs. For other reasons, customers may be responsible for
            return shipping fees.
            <br />
            <br />
            To return an item, please email customer service at
            <a href="mailto:info@zayadryfruits.com"> info@zayadryfruits.com </a>
            to obtain a Return Merchandise Authorization (RMA) number. After
            receiving a RMA number, place the item securely in its original
            packaging and include your proof of purchase, and mail your return
            to the following address:
            <br />
            <br />
            <ul>
              <li>
                26, Central Market, Lajpat Nagar II, Lajpat Nagar, New Delhi
                Delhi 110024 Attn: Returns RMA #
              </li>
              <li>
                26, Central Market, Lajpat Nagar II, Lajpat Nagar New Delhi,
                Delhi 110024 India
              </li>
            </ul>
            Return shipping charges will be paid or reimbursed by us.
          </p>
          <h3>Exchanges</h3>
          <p>
            If you'd prefer an exchange rather than a refund, we can arrange for
            replacement of the item, subject to product availability.
          </p>
        </div>
        <h2>Cancellation Policy</h2>
        <div class="policy-section">
          <h3>Order Cancellation</h3>
          <p>
            You may cancel your order within 60 minutes of placing it. After an
            hour orders are processed and shipped, and cancellations may not be
            possible.
          </p>

          <h3>How to Cancel</h3>
          <p>
            To cancel an order, please contact our customer service team as soon
            as possible via email or phone with your order details.
          </p>
          <h3>Refunds</h3>
          <p>
            If your order is successfully cancelled, a full refund will be
            issued to your original payment method within 5-7 business days.
          </p>
          <h3>Non-Cancellable Orders</h3>
          <p>
            Once the order is dispatched, cancellations are no longer accepted.
          </p>
          <h3>Right to Cancel Orders</h3>
          <p>
            We reserve the right to cancel any order at our discretion,
            including orders that may appear suspicious or where payment has not
            been successfully processed. In such cases, you will be notified,
            and any payments made will be fully refunded.
          </p>
        </div>
        <h3>Questions</h3>
        <p>
          If you have any questions concerning our return policy, please contact
          us at: <a href="tel:+(91) 99583 44409">+(91) 99583 44409</a> /
          <a href="mailto:info@zayadryfruits.com"> info@zayadryfruits.com</a>
        </p>
        <p className="textColor">Please Note</p>
        <li> Sale items are FINAL SALE and cannot be returned.</li>
      </div>
    </div>
  );
};

export default ShippingPolicy;
