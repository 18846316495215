import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { ImgURL } from "../url/api";
const About = () => {
  const sectionRef = useRef(null);
  useEffect(() => {
    if (window.location.hash === "#about-us") {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  const currentPageUrl = window.location.href;
  return (
    <div className="container px-4 text-center" style={{ marginTop: 75 }}>
      <Helmet>
        <title>About Us</title>
        <meta property="og:title" content={"About Us"} />
        <meta property="og:url" content={currentPageUrl} />
        <meta property="og:site_name" content="Zaya Dryfruits" />
      </Helmet>
      <br />
      <h4 className="textColor" ref={sectionRef}>
        About Us
      </h4>
      <hr />
      <div className="row gx-5">
        <div className="col-md-6">
          <div className="p-3">
            <p className="textColor">WE ARE ZAYA DRYFRUITS</p>
            <h2 className="textColor">The Best Trusted Company</h2>
            <p style={{ textAlign: "center" }}>
              ZAYA DRYFRUITS, leading the way in developing extraordinary
              products from quality ingredients, getting from diverse
              industries, has ability to respond to changing consumer needs and
              retain their competitive edge, locally and globally. Our expertise
              in this area is vast – from dry fruits, nuts, pulses and spices
              (masale), biscuits, baked snacks and dried fruits.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="p-3">
            <img
              src={`${ImgURL}/footer_banner/banner3.jpg`}
              alt="zayadryfruits"
              loading="lazy"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="p-3">
            <p className="textColor pTag">Best Seller</p>
            <h2 className="textColor">Zaya Dryfruits</h2>
            <p style={{ textAlign: "center" }}>
              The world is growing digital day by day. At Zaya Dryfruits, we
              make it easier for you to buy these tasty and nutritious dried
              fruits online. We, at Zaya Dryfruits , offer you over a 100+
              products best- suited for leading an active lifestyle.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="p-3">
            <img
              src={`${ImgURL}/footer_banner/banner1.jpeg`}
              alt="zayadryfruits"
              loading="lazy"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </div>
        <div className="about_us container">
          <h6>Welcome to zaya dryfruits</h6>
          <p>
            We are passionate about offering you a selection of healthy,
            one-of-a-kind nuts, imported from different countries, and
            conveniently delivered to your doorstep for your enjoyment with
            family.
          </p>
          <h6>GOAL</h6>
          <p>
            Founded in 1975 our goal is to inspire healthy living by introducing
            Zaya Dryfruits, with a commitment to freshness, exceptional quality,
            and ultimate convenience.
          </p>
          <h6>Specialisation</h6>
          <p>
            We directly import products and trade B2B across India through our
            flagship outlet at Khari Baoli, distributing to our retail outlets
            in Delhi/NCR. This allows us to supply fresh products by procuring
            only what’s needed, avoiding bulk storage and cold storage, ensuring
            quality and efficiency in delivery to our customers
          </p>
          <h6>Inside us</h6>
          <p>
            Our team is specially trained to understand customer needs and
            requirements, ensuring that we consistently deliver products that
            meet their expectations while maintaining the highest standards of
            quality.
          </p>
          <h6>All in All</h6>
          <p>
            Whether you're seeking products for personal use, gifts for loved
            ones, corporate needs, or B2B trade, we are always here to assist
            you.
            <br />
            We invite you to explore our website and discover how Zaya dryfruits
            can help you maintaining a healthy lifestyle.
          </p>
          <p>
            Thank you for choosing us. We look forward to being part of your
            experience.
          </p>
          <h6>Stores</h6>
          <ul class="store-list">
            <li>
              <div class="store-info">
                <span class="card store-details">
                  <p>
                    <i class="fas fa-map-marker-alt"></i> 26, Central Market
                    Lajpat Nagar-2 Near Rama Krishna Jewellers
                  </p>
                  <p>
                    <i class="fas fa-phone"></i>
                    <a href="tel: 9311716700">9311716700</a>
                  </p>
                </span>
              </div>
            </li>
            <li>
              <div class="store-info">
                <span class="card store-details">
                  <p>
                    <i class="fas fa-map-marker-alt"></i> 1, CC, New Friends
                    Colony Grandley Cinema, Near Post Office
                  </p>
                  <p>
                    <i class="fas fa-phone"></i>
                    <a href="tel: 9958344409"> 9958344409</a>
                  </p>
                </span>
              </div>
            </li>
            <li>
              <div class="store-info">
                <span class="card store-details">
                  <p>
                    <i class="fas fa-map-marker-alt"></i> G-8 Sector-18 Noida
                    Near Barista Coffee
                  </p>
                  <p>
                    <i class="fas fa-phone"></i>
                    <a href="tel: 9599200765">9599200765</a>
                  </p>
                </span>
              </div>
            </li>
          </ul>
        </div>
        {/* <div className="col-md-12">
          <h2 className="textColor">About Zaya Dry Fruits</h2>
          <p style={{ textAlign: "center" }}>
            Zaya Dryfruits (Khari Bobli Wale). As old as Khari Baoli is, so are
            Zaya dry fruits. In Digital Era Zaya make is Easier for you to buy
            these tasty and nutritious dried fruits online. We, at Zaya
            Dryfruits , offer you over a 100+ products best- suited for leading
            an active lifestyle.
          </p>
          <p style={{ textAlign: "center" }}>
            Zaya Dryfruits is in the process of bringing you healthy, pure food
            that could be a better alternative to unhealthy new generations.
            With customers and their health in mind, we are always trying and
            trying to do things to move forward, and recently introduced
            something new - in our stores, now we have introduced the idea of
            putting our dried fruit and nuts in the fridge. This, in turn,
            enhances the longevity of nuts and dried fruits, ensuring that all
            of their health and nutritional benefits are protected whenever you
            eat them. We have very good collection related to given bellow
            category:-
          </p>
        </div>
        <div className="col-md-12" style={{ textAlign: "left" }}>
          <h4 className="textColor">Dehydrated Fruits and Berries</h4>
          <p>
            We have a wide range of dehydrated fruits and berries. We have
            Quality products in bulk and retailer also. Here Online Grave is
            your Perfect product for you.
          </p>
        </div>
        <div className="col-md-12" style={{ textAlign: "left" }}>
          <h4 className="textColor">Coated Nuts</h4>
          <p>
            Zaya Dryfruits have very good quality of coated nuts Here you will
            get coted nuts at best price.
          </p>
        </div>
        <div className="col-md-12" style={{ textAlign: "left" }}>
          <h4 className="textColor">Seeds</h4>
          <p>
            We have best quality of seed at best price online. Now con grave
            your product. Quality Product make you smile.
          </p>
        </div>
        <div className="col-md-12" style={{ textAlign: "left" }}>
          <h4 className="textColor">Masale</h4>
          <p>
            Masala of Zaya Dryfruits will add more tests to the recipe. So grave
            your Masale from Zayadryfruits.com with best price.
          </p>
        </div>
        <div className="col-md-12" style={{ textAlign: "left" }}>
          <h4 className="textColor">Pulses</h4>
          <p>
            Online Best Quality Pulses (दालें) grave with us. We offer you the
            Best Pulses at the best price. Variety of products that have their
            own virtue.
          </p>
        </div>
        <div className="col-md-12" style={{ textAlign: "left" }}>
          <h4 className="textColor">Namkeens</h4>
          <p>
            Are You looking for Namkeens? Right Place you are in. Thank you We
            have a wide range of Namkeens for You. Test it then you realize that
            you have chosen the best one. Select your Product from the Namkeen
            section and test it.
          </p>
        </div>
        <div className="col-md-12" style={{ textAlign: "left" }}>
          <h4 className="textColor">Ayurveda</h4>
          <p>
            We have pure organic Ayurveda products without any chemical
            reaction.Order Now !
          </p>
        </div>
        <div className="col-md-12" style={{ textAlign: "left" }}>
          <h4 className="textColor">Mouth Freshener</h4>
          <p>
            Buy Organic Mouth Freshener from Zaya Dryfruits. We have a wide
            range of mouth Freshener. Grave you mouth freshener at best price.
          </p>
        </div>
        <div className="col-md-12" style={{ textAlign: "left" }}>
          <h4 className="textColor">DATES (KHAJOOR)</h4>
          <p>
            Dates are Popularly known as Khajoor in India. Are the fruits of a
            date palm tree. Buy Online this winter's best Dates (Khajoor) at the
            best price.
          </p>
        </div>
        <div className="col-md-12" style={{ textAlign: "left" }}>
          <h4 className="textColor">NEW LAUNCH</h4>
          <p>
            In This Section, you will get our new Launch product. Which you will
            get it on your demand. We are going to this section update soon.
          </p>
        </div>
        <div className="col-md-12" style={{ textAlign: "left" }}>
          <h4 className="textColor">Giftings</h4>
          <p>
            Gifting Section is designed for you. So that you can order an online
            gift pack for your loved one. In the Gift section, you can choose a
            number of items you are for friends and for family also.
          </p>
        </div>
        <div className="col-md-12" style={{ textAlign: "left" }}>
          <h4 className="textColor">Executive Class</h4>
          <p>
            Order online Executive class product With best Quality of Dry
            Fruits. Here you can buy online best product.
          </p>
        </div>
        <div className="col-md-12" style={{ textAlign: "left" }}>
          <h4 className="textColor">Dry Fruits</h4>
          <p>
            Zaya Dry Fruits have a house of dry fruits with the best quality.
            You can order the best quality Dry Fruits Products order online.
            Zaya also provides it wholesale.
          </p>
        </div>
        <div className="col-md-12" style={{ textAlign: "left" }}>
          <h4 className="textColor">Other Products</h4>
          <p>
            Here you will get our best organic product which you can customize
            according to your test. Buy your Aam Papad Amla Candy PLAIN
            (REGULAR), Bhuna Chana, Almond Chocodip, STONE CHOCOLATE (SOFT),
            SUGAR, SOOJI, POHA (CHIDWA), GUD PLAIN (JAGGERY), MISHRI DHAGE WALI,
            BHUNA CHANA, BHUNA CHANA (WITHOUT SHELL), DRY GOLA (COCONUT), GOUND,
            GOUND KATIRA, Etc.
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default About;
