import React, { useState, useEffect } from "react";
import logo from "../asset/pngImage/logo.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import Cart from "../page/Cart";
import { useSelector, useDispatch } from "react-redux";
import { refreshCart } from "../reduxSetup/slices/productsSlice";
import { URL } from "../url/api";

export default function Header(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const cookieValue = Cookies.get("user_session");
  const searchParams = new URLSearchParams(location.search);
  const search = searchParams.get("q");
  const [searchTerm, setSearchTerm] = useState("");
  const count = useSelector((state) => state.product.data);
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);
  useEffect(() => {
    checkLogin();
    return () => {
      checkLogin();
    };
  }, []);
  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        getCartData(token);
      } else {
        console.log("isLoggedIn::::::", session);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };
  const getCartData = (session) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${session}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}/getCart`, requestOptions)
      .then((response) => {
        if (response.status === 401) {
          Cookies.remove("user_session");
          Cookies.remove("user_address");
        } else if (response.ok) {
          return response.text();
        } else {
          throw new Error("Network response was not ok");
        }
      })
      .then((result) => {
        const newData = JSON.parse(result);
        newData.data.cart.map((item) => {
          dispatch(refreshCart(item));
        });
        if (newData?.data?.cart?.length === "0") {
          navigate("/", { replace: true });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      navigate(`/search?q=${encodeURIComponent(searchTerm)}`);
    }
  };
  const handleSearch = () => {
    // Navigate to the search results page with the search term as a query parameter
    navigate(`/search?q=${encodeURIComponent(searchTerm)}`);
  };
  return (
    <div>
      <nav
        className="navbar navbar-expand-lg fixed-top"
        style={{ backgroundColor: "#efe9e3" }}
      >
        <div className="container-fluid">
          {/* zaya logo */}
          <a href={"/"} className="logoheader">
            <img
              src={logo}
              alt={"ZayaDryFruits"}
              loading="lazy"
              style={{ height: "100%", width: "70%" }}
            />
          </a>
          {/* toogle button in mobile view */}
          <button
            className="btn btn_offcanvas"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasWithBothOptions"
            aria-controls="offcanvasWithBothOptions"
            onClick={() => {
              setNavbarCollapsed(!navbarCollapsed);
            }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {/* search field in mobile view */}
          <div className="searchInput_fild">
            {search ? null : (
              <div className="col-auto my-2 mx-2" id="searchDetails">
                <div className="input-group">
                  <input
                    type="search"
                    placeholder="Search"
                    className="form-control"
                    id="autoSizingInputGroup1"
                    value={searchTerm}
                    onKeyPress={handleKeyPress}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn-primary p-2"
                    onClick={() => {
                      handleSearch();
                      setNavbarCollapsed(true);
                    }}
                  >
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </button>
                </div>
              </div>
            )}
          </div>

          <div
            className={`offcanvas offcanvas-start ${
              navbarCollapsed ? "" : "show"
            }`}
            style={{ width: "75%" }}
            data-bs-scroll="true"
            tabIndex="-1"
            id="offcanvasWithBothOptions"
            aria-labelledby="offcanvasWithBothOptionsLabel"
          >
            <div className="offcanvas-body">
              {/* zaya logo */}
              <a href={"/"} className="logoheader1">
                <img
                  src={logo}
                  alt={"ZayaDryFruits"}
                  loading="lazy"
                  style={{
                    height: "6%",
                    width: "40%",
                    marginLeft: "25%",
                  }}
                />
              </a>
              <hr />
              {/* navbar items */}
              <ul className="navbar-nav mb-2 mb-lg-0 w-50">
                <li className="nav-item text-align-left">
                  <Link
                    className="nav-link"
                    aria-current="page"
                    to="/"
                    onClick={() => setNavbarCollapsed(true)}
                  >
                    {props.title}
                  </Link>
                </li>
                {/* <li className="nav-item text-align-left">
                  <Link
                    className="nav-link"
                    to={`/grocery/${"10"}/${"Ayurveda"}`}
                    // to="/shop/1"
                    onClick={() => setNavbarCollapsed(true)}
                  >
                    {props.Shop}
                  </Link>
                </li> */}
                <li className="nav-item text-align-left">
                  <Link
                    className="nav-link"
                    to={`/Khari-Baoli-Dry-Fruits-Rate-List-Today`}
                    // to="/shop/1"
                    onClick={() => setNavbarCollapsed(true)}
                  >
                    {props.All}
                  </Link>
                </li>
              </ul>
              {/* search field in web view */}
              <div className="searchInput_fild1 w-50 me-5">
                {search ? null : (
                  <div className="input-group">
                    {/* <div className="input-group-text">@</div> */}
                    <input
                      type="search"
                      placeholder="Search"
                      className="form-control"
                      id="autoSizingInputGroup"
                      value={searchTerm}
                      onKeyPress={handleKeyPress}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        handleSearch();
                        setNavbarCollapsed(true);
                      }}
                    >
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* loginbutton or profile icon */}
          <div style={{ alignItems: "center", alignSelf: "center" }}>
            {cookieValue == null ? (
              <Link
                className="nav-link "
                to="/login"
                onClick={() => setNavbarCollapsed(true)}
              >
                <button
                  type="button"
                  className="btn btn-primary m-2 registerbutton"
                >
                  login/Register
                </button>
                <div
                  className="text-center loginbutton"
                  style={{ lineHeight: 0.2 }}
                >
                  <i className="fa fa-right-to-bracket my-icon p-0" />
                  <br />
                  <span style={{ fontSize: 12, marginTop: 0 }}>login</span>
                </div>
              </Link>
            ) : (
              <Link
                className="nav-link "
                to="/profile"
                onClick={() => setNavbarCollapsed(true)}
              >
                <i className="fa fa-user-circle my-icon profileicon" />
                <i className="fa fa-user-circle my-icon profileicon1" />
                {/* <span className="profileicon1">Profile</span> */}
              </Link>
            )}
          </div>
          {/* cart icon */}
          <div
            className="cartdiv d-flex"
            style={{
              alignItems: "center",
            }}
          >
            <i
              className="fa-solid fa-bag-shopping my-icon"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasWithBackdrop"
              aria-controls="offcanvasWithBackdrop"
              role="button"
            />
            {count?.length > 0 ? <p className="cart">{count?.length}</p> : null}
          </div>
        </div>
      </nav>
      <Cart />
    </div>
  );
}
