import React, { useState, useRef, useEffect } from "react";
import Banner from "../asset/pngImage/Banner.png";
import { URL } from "../url/api";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import ActivityIndicator from "../component/ActivityIndicator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { refreshCart } from "../reduxSetup/slices/productsSlice";
import { Helmet } from "react-helmet";

const Login = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const currentPageUrl = window.location.href;
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [Token, setToken] = useState("");
  const [referal_code, setReferal_code] = useState("");
  const [show, setShow] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputRefs = useRef([]);
  const navigate = useNavigate();
  const [isValidEmail, setIsValidEmail] = useState(true); // Initially, assume email is valid
  const [timer, setTimer] = useState(30);
  const [eightSecondTimer, setEightSecondTimer] = useState(null);
  const [otpReceived, setOtpReceived] = useState(false);

  // set Phone Number in useState
  const handleInputChange = (e) => {
    const inputPhoneNumber = e.target.value;

    // Only update the state if the input length is less than or equal to 10
    if (inputPhoneNumber.length <= 10) {
      setPhone(inputPhoneNumber);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    let interval;
    if (otpReceived) {
      clearInterval(interval);
    } else {
      interval = setInterval(() => {
        if (timer > 0) {
          setTimer(timer - 1);
        } else {
          clearInterval(interval);
        }
      }, 1000);
    }
    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [timer, otpReceived]);

  //get otp use number  to user verify use this api
  const loginCheck = () => {
    if (phone) {
      var formdata = new FormData();
      formdata.append("os", "WEB");
      formdata.append("app_version", "0.0.1");
      formdata.append("mobile", phone);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      fetch(`${URL}/login`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const resultResponse = JSON.parse(result);
          if (resultResponse.error == false) {
            setShow(true);
            setTimer(30);
            setEightSecondTimer(8);
            const changeData = resultResponse.msg.replace(/[\{\}\[\]]/g, "");
            showToast(changeData);
          } else {
            const changeData = resultResponse.msg.replace(/[\{\}\[\]]/g, "");
            showToastErr(changeData);
          }
        })
        .catch((error) => {
          console.log("err::::::::", error);
        });
    }
  };
  const showToast = (title) => {
    toast.success(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const showToastErr = (title) => {
    toast.error(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleOTPInputChange = (e, index) => {
    const value = e.target.value;
    if (isNaN(value)) {
      return;
    }

    const updatedOtp = [...otp];
    updatedOtp[index] = value;
    setOtp(updatedOtp);

    if (value !== "") {
      focusNextInput(index);
    }
  };

  //use Next input change in otp section
  const focusNextInput = (index) => {
    if (inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  //use Previous input change in otp section
  const focusPrevInput = (index) => {
    if (inputRefs.current[index - 1]) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleResendOTP = () => {
    // setTimer(10); // Reset the timer
    loginCheck();
  };

  // user fill otp then  user login  use this api
  const loginVerify = () => {
    if (otp) {
      var formdata = new FormData();
      formdata.append("os", "WEB");
      formdata.append("app_version", "0.0.1");
      formdata.append("mobile", phone);
      formdata.append("otp", otp.join(""));

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };
      fetch(`${URL}/loginVerify`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const resultResponse = JSON.parse(result);
          if (resultResponse.error === false) {
            const changeData = resultResponse.msg.replace(/[\{\}\[\]]/g, "");
            showToast(changeData);
          } else {
            const changeData = resultResponse.msg.replace(/[\{\}\[\]]/g, "");
            showToastErr(changeData);
          }
          if (resultResponse?.data?.name === null) {
            Cookies.set(
              "user_session",
              JSON.stringify(resultResponse.data.token)
            );
            setToken(resultResponse.data.token);
            setVisible(true);
            setShow(false);
            getCartData(resultResponse?.data?.token);
          } else {
            Cookies.set(
              "user_session",
              JSON.stringify(resultResponse.data.token)
            );
            setToken(resultResponse.data.token);
            navigate(-1);
            getCartData(resultResponse?.data?.token);
            setShow(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getCartData = (session) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${session}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}/getCart`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        {
          newData.data.cart.map((item) => {
            dispatch(refreshCart(item));
          });
        }
        // if (newData?.data?.cart?.length == "0") {
        //   navigate("/", { replace: true });
        // }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // set Name in useState
  const nameInputChange = (e) => {
    setName(e.target.value);
  };
  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };
  // set Email in useState
  const emailInputChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);
    setIsValidEmail(validateEmail(inputValue));
  };
  // set Referral Code in useState
  const referralInputChange = (e) => {
    setReferal_code(e.target.value);
  };
  // if user new then Register use this api
  const setProfile = async () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = {
      name: name,
      email: email,
      referal_code: referal_code,
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(formdata),
      redirect: "follow",
    };
    fetch(`${URL}/setProfile`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        if (newData.error === false) {
          navigate(-1);
          setVisible(false);
        }
        setLoading(false);
        const changeData = newData.msg.replace(/[\{\}\[\]]/g, "");
        showToast(changeData);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  if (loading) {
    return <ActivityIndicator />;
  }
  return (
    <div className="container-fluid" style={{ marginTop: 65 }}>
      <Helmet>
        <title>Login</title>
        <meta property="og:title" content={"Login"} />
        <meta property="og:url" content={currentPageUrl} />
        <meta property="og:site_name" content="Zaya Dryfruits" />
      </Helmet>
      <div className="row">
        <div className="col-md-6 loginformbanner ">
          <img
            src={Banner}
            className="img-fluid"
            loading="lazy"
            alt={"ZayaDryFruits"}
            style={{
              width: "90%",
              margin: "5%",
            }}
          />
        </div>
        <div className="col-md-6 loginform">
          {visible === true ? (
            <div style={{ paddingTop: "10%", paddingBottom: "10%" }}>
              <h3 className="text-center mt-3">Register</h3>
              <label htmlFor="fn" className="form-label loginlabel mt-0 mb-0">
                Full Name
              </label>
              <input
                type="text"
                className="form-control logininputfield"
                id="fn"
                placeholder="Full Name"
                required
                onChange={nameInputChange}
              />
              <label
                htmlFor="EmailID"
                className="form-label loginlabel mt-0 mb-0"
              >
                Email
              </label>
              <input
                type="Email"
                className="form-control logininputfield"
                id="EmailID"
                placeholder="Email"
                required
                onChange={emailInputChange}
              />
              {!isValidEmail && (
                <p
                  className="loginlabel"
                  style={{
                    color: "red",
                    fontSize: 12,
                    marginTop: 2,
                  }}
                >
                  Invalid email address
                </p>
              )}
              <label
                htmlFor="ReferralID"
                className="form-label loginlabel mt-0 mb-0"
              >
                Referral Code
              </label>
              <input
                type="text"
                className="form-control logininputfield"
                id="ReferralID"
                placeholder="Referral Code"
                required
                onChange={referralInputChange}
              />
              <div className="col-auto getotpbutton">
                <button
                  type="submit"
                  className="btn btn-primary "
                  style={{ width: "100%" }}
                  onClick={() => {
                    setProfile();
                  }}
                >
                  Register
                </button>
              </div>
            </div>
          ) : (
            <>
              {show === false ? (
                <div style={{ paddingTop: "10%", paddingBottom: "10%" }}>
                  <h3 className="text-center mt-3">Login</h3>
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label loginlabel"
                  >
                    Phone Number
                  </label>
                  <input
                    type="number"
                    className="form-control logininputfield"
                    id="exampleFormControlInput1"
                    placeholder="Mobile"
                    required
                    onChange={handleInputChange}
                    onKeyPress={(event) => {
                      if (event.target.value.length >= 10) {
                        event.preventDefault(); // Prevent entering more characters
                      }
                    }}
                  />
                  <div className="col-auto getotpbutton mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary "
                      style={{ width: "100%" }}
                      onClick={() => {
                        loginCheck();
                      }}
                    >
                      Get OTP
                    </button>
                  </div>
                </div>
              ) : (
                <div style={{ paddingTop: "10%", paddingBottom: "10%" }}>
                  <h3 className="text-center">Enter OTP</h3>
                  <label
                    htmlFor="exampleFormControlInput1"
                    className="form-label"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: 15,
                      fontWeight: "500",
                    }}
                  >
                    Enter 4 Digit OTP sent on {phone}
                  </label>
                  <br />
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: 15,
                    }}
                  >
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        type="text"
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handleOTPInputChange(e, index)}
                        onFocus={(e) => e.target.select()}
                        onKeyDown={(e) => {
                          if (e.key === "Backspace" && digit === "") {
                            focusPrevInput(index);
                          }
                        }}
                        ref={(input) => (inputRefs.current[index] = input)}
                        style={{ width: "10%", margin: 5, textAlign: "center" }}
                      />
                    ))}
                  </div>
                  <div className="text-center" role="button">
                    <p
                      style={{
                        color: "red",
                        marginTop: 5,
                        fontSize: 12,
                        fontWeight: "500",
                      }}
                      onClick={handleResendOTP}
                    >
                      {otpReceived
                        ? "OTP Received!"
                        : timer > 0
                        ? `Time Left: ${timer}s`
                        : "Resend OTP"}
                    </p>
                  </div>
                  <div className="col-auto getotpbutton mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary "
                      style={{ width: "100%" }}
                      onClick={() => {
                        loginVerify();
                      }}
                    >
                      Verify OTP
                    </button>
                  </div>
                </div>
              )}
            </>
          )}

          {/* </form> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
