import React, { useEffect, useState } from "react";
import Categories from "../component/Categories";
import ProductCategories from "../component/ProductCategories";
import { URL, ImgURL } from "../url/api";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
function Shop() {
  const { id } = useParams();
  const currentPageUrl = window.location.href;
  // const currentUrl = window.location.origin;
  const [data, setData] = useState([]);
  const [subcategory, setSubcategory] = useState([]);
  const [first_catg_products, setFirst_catg_products] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [page, setPage] = useState(1);
  const [dataDetails, setDataDetails] = useState("");
  const [groceryID, setGroceryID] = useState("");
  useEffect(() => {
    getSubCategories();
    getProductByCategory(id);
    checkLogin();
    setGroceryID(id);
  }, [id]);

  useEffect(() => {
    // Adjust the container height dynamically based on the viewport height
    const updateContainerHeight = () => {
      const windowHeight = window.innerHeight;
      const containerHeight = windowHeight; // Adjust as needed
      const productCategoriesContainer = document.getElementById(
        "product-categories-container"
      );

      if (productCategoriesContainer) {
        productCategoriesContainer.style.height = `${containerHeight}px`;
      }
    };

    // Call the function initially and on window resize
    updateContainerHeight();
    window.addEventListener("resize", updateContainerHeight);

    return () => {
      window.removeEventListener("resize", updateContainerHeight);
    };
  }, []);
  useEffect(() => {
    // Adjust the container height dynamically based on the viewport height
    const updateContainerHeight = () => {
      const windowHeight = window.innerHeight;
      const containerHeight = windowHeight; // Adjust as needed
      const productCategoriesContainer = document.getElementById(
        "product-subCategories-container"
      );

      if (productCategoriesContainer) {
        productCategoriesContainer.style.height = `${containerHeight}px`;
      }
    };

    // Call the function initially and on window resize
    updateContainerHeight();
    window.addEventListener("resize", updateContainerHeight);

    return () => {
      window.removeEventListener("resize", updateContainerHeight);
    };
  }, []);

  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        // const token = JSON.parse(session);
      } else {
        setIsLoggedIn(true);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };
  const getSubCategories = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${URL}/getCategoriesById`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        setData(newData.data);
      })
      .catch((error) => console.log("error", error));
  };

  const handleSelectSubcategory = (subcategoryId) => {
    setDataDetails(subcategoryId);
    getProductByCategory(subcategoryId.cate_id);
  };
  const getProductByCategory = (cat_Id) => {
    setLoading(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${URL}/getProductByCategoryId/${cat_Id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setLoading(false);
        const newData = JSON.parse(result);
        // setSubcategory(newData.data.subcategory);
        setFirst_catg_products(newData.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log("getProductByCategory::", error);
      });
  };

  const itemListElements = first_catg_products.map((product, index) => ({
    "@type": "ListItem",
    position: index + 1,
    item: {
      "@type": "Product",
      name: product?.product.name,
      url: `http://localhost:3000/product/${
        product?.product?.id
      }/${encodeURIComponent(product?.product?.name)}`,
      image: `${ImgURL}${product?.product?.img}`,
      description: product?.product?.description || "",
    },
  }));

  const schemaData = {
    "@context": "http://schema.org",
    "@type": "ItemList",
    name: dataDetails?.name || "",
    url: currentPageUrl || "",
    itemListElement: itemListElements,
  };
  return (
    <div className="Container" style={{ marginTop: 75 }}>
      <Helmet>
        <title>{dataDetails?.name}</title>
        <meta name="Description" content={dataDetails?.description} />
        <meta name="KeyWords" content="DryFruits, Nuts, Pulses" />
        <meta property="og:title" content={dataDetails?.name} />
        <meta property="og:description" content={dataDetails?.description} />
        <meta property="og:url" content={currentPageUrl} />
        <meta property="og:site_name" content="Zaya Dry Fruits" />
        <meta property="og:image" content={`${ImgURL}${dataDetails.img}`} />
        <meta name="author" content="Zaya Dry fruits" />
        <meta name="copyright" content="Zaya Dry fruits" />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta property="og:type" content="business.business" />
      </Helmet>
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>

      <div className="col-12 mt-5 ">
        <div className="row">
          <div className="col-md-2 SubCategoriesProduct">
            <div
              id="product-subCategories-container"
              className="product-subCategories-container"
              style={{ overflow: "auto" }}
            >
              <Categories
                data={data}
                subcategory={subcategory}
                groceryID={groceryID}
                onSelectSubcategory={handleSelectSubcategory}
              />
            </div>
          </div>
          <div className="col-md-10 ProductCategories">
            <div
              id="product-categories-container"
              className="product-categories-container"
              style={{ overflow: "auto" }}
            >
              <h1
                className="textColor"
                style={{
                  fontSize: 18,
                  marginVertical: 10,
                  marginTop: 10,
                  marginLeft: 20,
                  // textAlign: "center",
                  fontWeight: "600",
                }}
              >
                {dataDetails.name}
              </h1>
              <ProductCategories
                first_catg_products={first_catg_products}
                loading={loading}
                isLoggedIn={isLoggedIn}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shop;
