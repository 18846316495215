import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { URL } from "../url/api";

const RedirectOldProductUrl = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const IdGetByUrl = searchParams.get("id");
  const productName = searchParams.get("product_name");

  useEffect(() => {
    // const fetchData = async (id) => {
    //   try {
    //     const response = await fetch(`${URL}/getProductByOldId/${id}`, {
    //       method: "GET",
    //       redirect: "follow",
    //     });

    //     if (!response.ok) {
    //       throw new Error("Failed to fetch data");
    //     }

    //     const result = await response.json();

    //     if (result?.data?.id && result?.data?.name) {
    //       navigate(`/product/${result.data.id}/${result.data.name}`);
    //     } else {
    //       console.error("Invalid data received");
    //       // Handle the case where data is not valid
    //     }
    //   } catch (error) {
    //     console.error(error);
    //     // Handle error (e.g., display an error message)
    //   }
    // };
    if (IdGetByUrl && productName) {
      navigate(`/search?q=${encodeURIComponent(productName)}`);
    }
  }, [IdGetByUrl, productName, navigate]);

  // Render nothing or a message if needed
  return null;
};

export default RedirectOldProductUrl;
