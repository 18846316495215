import { configureStore } from "@reduxjs/toolkit";
import ProductReducer from "../reduxSetup/slices/productsSlice";
import OrderIDReducer from "./slices/orderIDSlice";

export const store = configureStore({
  reducer: {
    product: ProductReducer,
    order:OrderIDReducer,
  },
});
