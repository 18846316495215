import React, { useState, useEffect } from "react";
import { URL, ImgURL } from "../url/api";
import Cookies from "js-cookie";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { OrderId } from "../reduxSetup/slices/orderIDSlice";
import ActivityIndicator from "../component/ActivityIndicator";
import { clearAllData } from "../reduxSetup/slices/productsSlice";
import { Helmet } from "react-helmet";
import almond from "../asset/pngImage/almond.png";
import Logo from "../asset/pngImage/logo.png";
import RadioFill from "../asset/pngImage/radiofil.png";
import Radio from "../asset/pngImage/radio.png";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const [Token, setToken] = useState("");
  const [payMode, setPayMode] = useState("cod");
  const [promo, setPromoCode] = useState("");
  const [instructions, setInstructions] = useState("");
  const [addressUser, setAddressUser] = useState("");
  const [totalCartDetails, setTotalCartDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [phone_No, setPhone_no] = useState("");
  const [house_no, setHouse_no] = useState("");
  const [locality, setLocality] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [isChecked, setIsChecked] = useState(0);
  useEffect(() => {
    checkLogin();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    userAddress();
  }, [addressUser]);

  useEffect(() => {
    if (isChecked && Token) {
      calculateCart(Token);
    }
  }, [isChecked, Token]);

  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        setToken(token);
        calculateCart(token);
      } else {
        console.log("isLoggedIn::::::", session);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };
  const userAddress = async () => {
    try {
      const userAddress = await Cookies.get("user_address");
      setAddressUser(JSON.parse(userAddress));
    } catch (error) {
      // There was an error on the native side
    }
  };
  const showToast = (title) => {
    toast.success(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const showToastErr = (title) => {
    toast.error(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const calculateCart = (session) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${session}`);

    var formdata = new FormData();
    formdata.append("pay_mode", payMode);
    formdata.append("address_id", id);
    formdata.append("use_wallet", isChecked);
    formdata.append("coupon_code", promo);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(`${URL}/calculateCart`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        setTotalCartDetails(newData.data);
      })
      .catch((error) => {});
  };
  const handalCheckCoupon = (e) => {
    setPromoCode(e.target.value);
  };
  const handalDeliveryInstructions = (e) => {
    setInstructions(e.target.value);
  };
  const checkCoupon = () => {
    if (promo === "") {
      showToastErr("Please enter promo code ");
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("coupon_code", promo);
    formdata.append("pay_mode", payMode);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${URL}/checkCoupon`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const resultResponse = JSON.parse(result);
        if (resultResponse.error === false) {
          calculateCart(Token);
          const changeData = resultResponse.msg.replace(/[\{\}\[\]]/g, "");
          showToast(changeData);
        } else {
          setPromoCode("");
          const changeData = resultResponse.msg.replace(/[\{\}\[\]]/g, "");
          showToastErr(changeData);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const createOrder = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("pay_mode", payMode);
    formdata.append("address_id", id);
    formdata.append("use_wallet", isChecked);
    formdata.append("coupon_code", promo);
    formdata.append("order_notes", instructions);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(`${URL}/createOrder`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        if (newData.error === false) {
          if (newData?.data?.pay_mode === "cod") {
            const check_id = newData?.data?.id;
            setInstructions("");
            setPayMode("cod");
            dispatch(OrderId(newData));
            dispatch(clearAllData());
            navigate(`/OrderPlaced/${check_id}`, { replace: true });
            showToast(newData.msg);
          } else {
            const order_id = newData?.data?.pg_order_id;
            const razorPay_id = newData?.data?.pg_key;
            const check_id = newData?.data?.id;
            // setCheckPayment_Id(newData?.data?.id);
            dispatch(OrderId(newData));
            dispatch(clearAllData());
            handlePayment(order_id, razorPay_id, check_id);
            // navigate(`/OrderPlaced/${check_id}`, { replace: true });
          }
        } else {
          setPromoCode("");
          showToast(newData.msg);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handlePayment = async (order_id, razorPay_id, check_id) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK faild to load. Are you online ?");
      return;
    }
    var options = {
      key: razorPay_id, // Enter the Key ID generated from the Dashboardcurrency: "INR",
      name: "Zaya Dry Fruits", //your business name
      // description: "Test Transaction",
      image: Logo,
      order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      callback_url: "https://eneqd3r9zrjok.x.pipedream.net/",
      prefill: {
        name: addressUser?.contact_person, //your customer's name
        // email: "gaurav.kumar@example.com",
        contact: addressUser?.contact_no, //Provide the customer's phone number for better conversion rates
      },
      theme: {
        color: "#3399cc",
      },
      handler: function (response) {
        checkPayment(check_id);
      },
      modal: {
        ondismiss: function () {
          checkPayment(check_id);
        },
      },
    };
    const paymentInstance = new window.Razorpay(options);
    paymentInstance.open();
  };
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const checkPayment = (check_id) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${URL}/checkPayment/${check_id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        if (newData.error === false) {
          setPayMode("cod");
          showToast(newData.msg);
        } else {
          showToastErr(newData.msg);
        }
        navigate(`/OrderPlaced/${check_id}`, { replace: true });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleImageError = (event) => {
    event.target.src = almond; // Use the logo variable from your imports
  };
  const nameInputChange = (e) => {
    setName(e.target.value);
  };
  const phoneInputChange = (e) => {
    const inputPhoneNumber = e.target.value;

    // Only update the state if the input length is less than or equal to 10
    if (inputPhoneNumber.length <= 10) {
      setPhone_no(inputPhoneNumber);
    }
  };
  const houseInputChange = (e) => {
    setHouse_no(e.target.value);
  };
  const nearAboutInputChange = (e) => {
    setLocality(e.target.value);
  };
  const cityInputChange = (e) => {
    setCity(e.target.value);
  };
  const stateInputChange = (e) => {
    setState(e.target.value);
  };
  const zipInputChange = (e) => {
    const inputPhoneNumber = e.target.value;

    // Only update the state if the input length is less than or equal to 10
    if (inputPhoneNumber.length <= 6) {
      setPincode(inputPhoneNumber);
    }
  };

  // Clean All Input Field  Add & Update api
  const handaleEditeAddressClean = (e) => {
    setName("");
    setPhone_no("");
    setHouse_no("");
    setLocality("");
    setCity("");
    setState("");
    setPincode("");
  };

  // Fill All Input Field To Use Update  Customer Address
  const handaleEditeAddress = (e) => {
    setName(e.contact_person);
    setPhone_no(e.contact_no);
    setHouse_no(e.house_no);
    setLocality(e.locality);
    setCity(e.city);
    setState(e.state);
    setPincode(e.pincode);
  };
  const updateCustomerAddress = () => {
    if (
      !house_no ||
      !locality ||
      !city ||
      !state ||
      !pincode ||
      !name ||
      !phone_No
    ) {
      showToastErr("Please fill in all required fields.");
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("id", id);
    formdata.append("house_no", house_no);
    formdata.append("locality", locality);
    formdata.append("city", city);
    formdata.append("state", state);
    formdata.append("pincode", pincode);
    formdata.append("contact_person", name);
    formdata.append("contact_no", phone_No);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${URL}/updateCustomerAddress`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        if (userData.error == false) {
          Cookies.set("user_address", JSON.stringify(userData?.data));
          const modal = document.getElementById("EditAddressInCart");
          if (modal) {
            modal.classList.remove("show");
            modal.style.display = "none";
            const modalBackdrop =
              document.getElementsByClassName("modal-backdrop")[0];
            if (modalBackdrop) {
              modalBackdrop.parentNode.removeChild(modalBackdrop);
            }
            document.body.classList.remove("modal-open");
          }
        }
        const changeData = userData.msg.replace(/[\{\}\[\]]/g, "");
        showToast(changeData);
      })
      .catch((error) => console.log("error", error));
  };
  if (loading) {
    return <ActivityIndicator />;
  }
  return (
    <div className="container-fluid">
      <div className="row topMargin mx-0">
        <Helmet>
          <title>Check Out</title>
        </Helmet>
        <div className="col-md-6">
          {totalCartDetails?.cart?.map((item) => {
            return (
              <div className="card checkoutdetail">
                <div className="col-md-4">
                  <img
                    key={item.id}
                    alt={item.product.name}
                    src={`${ImgURL}${item.product.img}`}
                    loading="lazy"
                    onError={handleImageError}
                    className="card-img-top mx-auto checkoutimage"
                  />
                </div>
                <div className="col-md-8 checkoutdetail">
                  <div>
                    <p
                      className="pTag"
                      style={{ fontSize: 14, fontWeight: 500 }}
                    >
                      {item.product.name} ({item.variant.size})
                    </p>
                    <p
                      className="pTag"
                      style={{ fontSize: 14, fontWeight: 500 }}
                    >
                      Quantity : {item.qty}
                    </p>
                  </div>
                  {/* <div> */}
                  <p className="pTag" style={{ fontSize: 14, fontWeight: 500 }}>
                    Selling Price: ₹ {item.variant.sp}
                  </p>
                  {/* <p className="pTag" style={{ fontSize: 14, fontWeight: 500 }}>
                    Size : {item.variant.size}
                  </p> */}
                  {/* </div> */}
                </div>
              </div>
            );
          })}
          <div
            className="card my-2"
            style={{
              flexDirection: "row",
              justifyContent: "space-around",
              padding: 8,
            }}
          >
            <p className="fw-bold pTag" style={{ fontSize: 14 }}>
              Total Items:{totalCartDetails?.item_count}
            </p>
            <p className="fw-bold pTag" style={{ fontSize: 14 }}>
              Total Amount: ₹{totalCartDetails?.total}
            </p>
          </div>
        </div>
        <div className="col-md-6 mt-2">
          <div className="card p-2">
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <h5> Address</h5>
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#EditAddressInCart"
                className="btn  btn-primary my-1"
                style={{ marginRight: 4 }}
                onClick={() => {
                  handaleEditeAddress(addressUser);
                }}
              >
                <i className="fa-solid fa-pen-to-square"></i>
              </button>
            </div>
            <div style={{ lineHeight: 1 }}>
              <div className="horizontalContainer1">
                <i className="fa-solid fa-user mx-2"></i>
                <p>{addressUser?.contact_person}</p>
              </div>
              <br />
              <div className="horizontalContainer1">
                <i className="fa-solid fa-phone mx-2"></i>
                <p>{addressUser?.contact_no}</p>
              </div>
              <br />
              {addressUser.house_no &&
                addressUser.locality &&
                addressUser?.city &&
                addressUser.pincode &&
                addressUser.state && (
                  <div className="horizontalContainer1">
                    <i className="fa-solid fa-location-dot mx-2"></i>
                    <p>
                      {addressUser.house_no},{addressUser.locality},
                      {addressUser?.city},{addressUser.pincode},
                      {addressUser.state}
                    </p>
                  </div>
                )}
            </div>
          </div>
          <div
            className="modal fade"
            id="EditAddressInCart"
            tabIndex="-1"
            aria-labelledby="EditAddressInCartLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="EditAddressInCartLabel">
                    Edit Address
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      handaleEditeAddressClean();
                    }}
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        aria-label="First name"
                        required
                        value={name}
                        onChange={nameInputChange}
                      />
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Phone Number"
                        aria-label="Last name"
                        required
                        value={phone_No}
                        onChange={phoneInputChange}
                        onKeyPress={(event) => {
                          if (event.target.value.length >= 10) {
                            event.preventDefault(); // Prevent entering more characters
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="row  my-4">
                    <div className="col">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="House Number"
                        aria-label="First name"
                        required
                        value={house_no}
                        onChange={houseInputChange}
                      />
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Near About"
                        aria-label="Last name"
                        required
                        value={locality}
                        onChange={nearAboutInputChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="City"
                        aria-label="City"
                        required
                        value={city}
                        onChange={cityInputChange}
                      />
                    </div>
                    <div className="col-sm-6 mt-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="State"
                        aria-label="State"
                        required
                        value={state}
                        onChange={stateInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-5 my-4">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Zip"
                      aria-label="Zip"
                      required
                      value={pincode}
                      onChange={zipInputChange}
                      onKeyPress={(event) => {
                        if (event.target.value.length >= 6) {
                          event.preventDefault(); // Prevent entering more characters
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      handaleEditeAddressClean();
                    }}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      updateCustomerAddress();
                    }}
                    // data-bs-dismiss="modal"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-0">
          <div className="card p-2 my-2">
            <div className="row my-2">
              <div className="col-md-7 w-50"> Discount</div>
              <div className="col-md-5 w-50 text-center">
                ₹ {totalCartDetails?.discount}
              </div>
            </div>
            <div className="row my-2">
              <div className="col-md-7 w-50"> Total Amount</div>
              <div className="col-md-5 w-50 text-center">
                ₹ {totalCartDetails?.total}
              </div>
            </div>
            <div className="row my-2">
              <div className="col-md-7 w-50"> Delivery Charges</div>
              <div className="col-md-5 w-50 text-center">
                ₹ {totalCartDetails?.delivery_charges}
              </div>
            </div>
            <div className="row my-2">
              <div className="col-md-7 w-50">Savings</div>
              <div className="col-md-5 w-50 text-center">
                ₹ {totalCartDetails?.savings}
              </div>
            </div>
            {isChecked === 0 ? null : (
              <div className="row my-2">
                <div className="col-md-7 w-50">Used Wallet</div>
                <div className="col-md-5 w-50 text-center">
                  − ₹ {totalCartDetails?.used_wallet}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-md-6 mb-2">
          <div className="card p-2 mt-2">
            <div className="row">
              <div className="col-md-7 w-50"> Payable Amount</div>
              <div className="col-md-5 w-50 text-center">
                ₹ {totalCartDetails?.payable_amt}
              </div>
              <div className="d-flex justify-content-space-around align-items-center">
                <div className="form-check">
                  <input
                    className="m-2"
                    type="radio"
                    id="cod"
                    value="cod"
                    checked={payMode === "cod"}
                    onChange={() => {
                      setPayMode("cod");
                    }}
                  />
                  <label htmlFor="cod">cod</label>
                </div>
                <div className="form-check">
                  <input
                    className="m-2"
                    type="radio"
                    id="online"
                    value="online"
                    checked={payMode === "online"}
                    onChange={() => {
                      setPayMode("online");
                    }}
                  />
                  <label htmlFor="online">online</label>
                </div>
              </div>
              <div className="d-flex  align-items-center">
                <div> Use Wallet</div>
                <input
                  className="m-2"
                  type="checkbox"
                  id="wallet"
                  value={isChecked}
                  checked={isChecked}
                  onChange={() => {
                    setIsChecked(isChecked === 0 ? 1 : 0);
                  }}
                  style={{ height: 20, width: 20 }}
                />
              </div>
            </div>
            <div className="row">
              {/* coupon code and apply button */}
              <input
                type="text"
                className="form-control promocode"
                id="formGroupExampleInput2"
                placeholder="PROMO CODE"
                value={promo}
                onChange={handalCheckCoupon}
              />
              <button
                className="btn btn-primary applybutton "
                type="submit"
                onClick={() => {
                  checkCoupon();
                }}
              >
                Apply
              </button>

              {/* delivery instruction div and placeorder button */}

              <div className="col-md-12 my-2">
                <textarea
                  type="message"
                  className="form-control"
                  id="formGroupExampleInput2"
                  placeholder="Give delivery Instructions"
                  onChange={handalDeliveryInstructions}
                />
              </div>
              <button
                className="btn btn-primary"
                type="submit"
                style={{ width: "80%", marginLeft: "10%" }}
                onClick={() => {
                  createOrder();
                }}
              >
                {payMode === "cod" ? "Place Order" : "Pay Now"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
