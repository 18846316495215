import React, { useState, useEffect } from "react";
import ActivityIndicator from "./ActivityIndicator";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { URL, ImgURL } from "../url/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { refreshCart } from "../reduxSetup/slices/productsSlice";
import almond from "../asset/pngImage/almond.png";

const ProductCategories = ({ first_catg_products, loading, isLoggedIn }) => {
  const dispatch = useDispatch();
  const [dataVariant, setDataVariant] = useState("");
  const [Token, setToken] = useState("");
  useEffect(() => {
    checkLogin();
  }, []);
  const showToast = (title) => {
    toast.success(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        setToken(token);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };
  const updateCart = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("variant_id", JSON.stringify(id));
    formdata.append("qty", "1");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(`${URL}/updateCart`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        if (newData.error === false) {
          getCart();
          showToast(newData.msg);
        }
      })
      .catch((error) => console.log("error", error));
  };
  //  only cart Update
  const getCart = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}/getCart`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        {
          newData.data.cart.map((item) => {
            dispatch(refreshCart(item));
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleImageError = (event) => {
    event.target.src = almond; // Use the logo variable from your imports
  };

  if (loading) {
    return <ActivityIndicator />;
  }
  return (
    <div className="container-fluid">
      <div className="row">
        {first_catg_products?.map((item) => {
          const image = item.product;
          let calculate = (image.variants[0].sp / image.variants[0].mrp) * 100;
          let Percentage = 100 - calculate;
          let name = image.name;
          const product = name.replace(/[\s()]+/g, "-").replace(/-+$/, "");
          return (
            <div key={item.id} className="col-md-2 p-1 col-6">
              <div className="card h-100 mt-2">
                <Link
                  className="my-nav-link"
                  to={`/product/${image.id}/${product}`}
                >
                  <div>
                    {image.variants[0].sp !== image.variants[0].mrp ? (
                      <div
                        style={{
                          height: 40,
                          width: 40,
                          position: "absolute",
                          backgroundColor: "#538cee",
                          left: 4,
                          borderBottomRightRadius: 10,
                          borderBottomLeftRadius: 10,
                        }}
                      >
                        <p
                          className="fw-bold"
                          style={{
                            fontSize: 10,
                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          {Percentage.toFixed(2)}% Off
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <img
                    key={image.id}
                    alt={image.img + "Image"}
                    src={`${ImgURL}${image.img}`}
                    className="card-img-top mx-auto"
                    loading="lazy"
                    onError={handleImageError}
                    style={{
                      height: "150px",
                      resizeMode: "contain",
                      width: "100%",
                      marginTop: 5,
                    }}
                  />
                  <div
                    style={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      WebkitLineClamp: 2,
                      textAlign: "center",
                      paddingTop: 10,
                    }}
                  >
                    <p className="fw-bold" style={{ fontSize: 12 }}>
                      {/* {image.name.length > 20
                      ? image.name.substring(0, 20) + "..."
                      : image.name} */}
                      {image.name}
                    </p>
                  </div>
                  <p
                    className="fw-bold text-center"
                    style={{ fontSize: 11, marginTop: 10 }}
                  >
                    {image.variants[0].size}
                  </p>
                </Link>
                <>
                  {image.variants[0].sp === image.variants[0].mrp ? (
                    <div
                      className="d-flex justify-content-evenly align-items-center"
                      style={{ marginBottom: 10 }}
                    >
                      <p
                        className="fw-bold"
                        style={{ fontSize: 11, margin: 0 }}
                      >
                        ₹{image.variants[0].sp}
                      </p>
                      <button
                        type="button"
                        className="btn btn-outline-success"
                        data-bs-toggle="modal"
                        data-bs-target="#variantsModal"
                        onClick={() => {
                          setDataVariant(image);
                        }}
                      >
                        Add
                      </button>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-evenly align-items-center">
                      <div>
                        <p
                          className="fw-bold"
                          style={{ fontSize: 11, margin: 0 }}
                        >
                          ₹{image.variants[0].sp}
                        </p>
                        <p style={{ fontSize: 10 }}>
                          <del>₹{image.variants[0].mrp}</del>
                        </p>
                      </div>
                      <button
                        type="button"
                        className="btn btn-outline-success"
                        data-bs-toggle="modal"
                        data-bs-target="#variantsModal"
                        onClick={() => {
                          setDataVariant(image);
                        }}
                      >
                        Add
                      </button>
                    </div>
                  )}
                </>
              </div>
            </div>
          );
        })}
        <div
          className="modal fade"
          id="variantsModal"
          tabIndex="-1"
          aria-labelledby="variantsModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="variantsModalLabel">
                  {dataVariant.name}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                {dataVariant ? (
                  <>
                    {dataVariant.variants.map((value) => {
                      return (
                        <div
                          className="container d-flex"
                          style={{
                            justifyContent: "space-between",
                            alignItems: "center",
                            margin: 6,
                          }}
                        >
                          {value.mrp === value.sp ? (
                            <>
                              <div>{value.size}</div>
                              <div className="d-flex">₹{value.sp}</div>
                              {!isLoggedIn ? (
                                <button
                                  type="button"
                                  className="btn btn-outline-success"
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#variantsModal"
                                  onClick={() => {
                                    updateCart(value.id);
                                  }}
                                  data-bs-dismiss="modal"
                                >
                                  Add
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-outline-success"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  // onClick={() => {
                                  //   console.log("e:::::::", value);
                                  // }}
                                >
                                  Add
                                </button>
                              )}
                            </>
                          ) : (
                            <>
                              <div>{value.size}</div>
                              <div className="d-flex">
                                <del style={{ marginRight: 4 }}>
                                  ₹{value.mrp}
                                </del>
                                ₹{value.sp}
                              </div>
                              {!isLoggedIn ? (
                                <button
                                  type="button"
                                  className="btn btn-outline-success"
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#variantsModal"
                                  onClick={() => {
                                    updateCart(value.id);
                                  }}
                                  data-bs-dismiss="modal"
                                >
                                  Add
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-outline-success"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                >
                                  Add
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      );
                    })}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCategories;
