const { createSlice } = require("@reduxjs/toolkit");
const ProductsSlice = createSlice({
  name: "products",
  initialState: {
    data: [],
  },
  reducers: {
    addProducts(state, action) {
      let tempData = state.data;
      let isItemExist = false;
      tempData.map((item) => {
        if (item.id == action.payload.id) {
          isItemExist = true;
          item.qty = item.qty + 1;
        }
      });
      if (!isItemExist) {
        tempData.push(action.payload);
      }
      state.data = tempData;
    },
    reduceItemToCart(state, action) {
      let tempData = state.data;
      tempData.map((item) => {
        if (item.id === action.payload.id) {
          item.qty = item.qty - 1;
        }
      });
      state.data = tempData;
    },
    removeItemToCart(state, action) {
      let tempData = state.data;
      tempData.splice(action.payload, 1);
      state.data = tempData;
    },
    refreshCart(state, action) {
      let tempData = state.data;
      let isItemExist = false;
      tempData.map((item) => {
        if (item.id === action.payload.id) {
          isItemExist = true;
        }
      });
      if (!isItemExist) {
        tempData.push(action.payload);
      }
      state.data = tempData;
    },
    clearAllData: (state) => {
      state.data = [];
    },
  },
});
export const {
  addProducts,
  removeItemToCart,
  reduceItemToCart,
  refreshCart,
  clearAllData,
} = ProductsSlice.actions;
export default ProductsSlice.reducer;
