import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { URL, ImgURL } from "../url/api";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import ActivityIndicator from "../component/ActivityIndicator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { refreshCart } from "../reduxSetup/slices/productsSlice";
import { Helmet } from "react-helmet";
import almond from "../asset/pngImage/almond.png";
import { Swiper, SwiperSlide } from "swiper/react";

const SingelProducts = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { pathname } = useLocation();
  const [Token, setToken] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idVariants, setIdVariants] = useState("");
  const [data, setData] = useState("");
  const [showFeaturedProducts, setShowFeaturedProducts] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [swiper, setSwiper] = useState(null);
  const currentPageUrl = window.location.href;
  useEffect(() => {
    checkLogin();
    if (id) {
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        setToken(token);
      } else {
        setIsLoggedIn(true);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };
  const handleImageClick = (path) => {
    setSelectedImage(path);
  };

  const showToast = (title) => {
    toast.success(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const fetchData = async () => {
    setLoading(true);
    try {
      fetch(`${URL}/getProductById/${id}`, {
        method: "GET",
        redirect: "follow",
      })
        .then((res) => res.text())
        .then((response) => {
          const result = JSON.parse(response);
          setData(result.data);
          setShowFeaturedProducts(result?.related_product);
          setSelectedImage(result?.data?.images[0]);
          setIdVariants(result.data.variants[0].id);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const updateCart = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("variant_id", JSON.stringify(idVariants));
    formdata.append("qty", "1");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(`${URL}/updateCart`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        if (newData.error === false) {
          getCart();
          showToast(newData.msg);
        }
      })
      .catch((error) => console.log("error", error));
  };
  //  only cart Update
  const getCart = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}/getCart`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        newData.data.cart.map((item) => {
          return dispatch(refreshCart(item));
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleImageError = (event) => {
    event.target.src = almond; // Use the logo variable from your imports
  };
  if (loading) {
    return <ActivityIndicator />;
  }
  return (
    <div
      // className="container"
      style={{
        flex: 1,
        marginTop: 75,
        marginRight: 10,
        marginLeft: 10,
      }}
      className="row d-flex"
    >
      <Helmet>
        <title>{data?.name}</title>
        <meta name="Description" content={data?.meta_desc} />
        <meta name="KeyWords" content={data?.meta_keyword} />
        <meta property="og:title" content={data?.name} />
        <meta property="og:description" content={data?.meta_desc} />
        <meta property="og:url" content={currentPageUrl} />
        <meta property="og:site_name" content="Zaya Dry Fruits" />
        <meta property="og:image" content={`${ImgURL}${data.img}`} />
        <meta name="author" content="Zaya Dry fruits" />
        <meta name="copyright" content="Zaya Dry fruits" />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta property="og:type" content="product" />

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-8EXFWPNHC8"
        ></script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-180675332-2"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
              function gtag() {
                dataLayer.push(arguments);
              }
              gtag('js', new Date());
              gtag('config', 'G-8EXFWPNHC8');
            `}
        </script>
        <script>
          {`{ window.dataLayer = window.dataLayer || [];
              function gtag() {
                  dataLayer.push(arguments);
              }
              gtag('js', new Date());
              gtag('config', 'UA-180675332-2');}`}
        </script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-353927359"
        ></script>
        <script>
          {`{  window.dataLayer = window.dataLayer || [];
              function gtag() {
                  dataLayer.push(arguments);
              }
              gtag('js', new Date());

              gtag('config', 'AW-353927359');}`}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "Product",
              "description": "${data?.description || ""}",
              "name": "${data?.name || ""}",
              "image": "${ImgURL}${data.img || ""}",
              "url": "${currentPageUrl || ""}",
              "sku": "${data?.sku_code || ""}",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.2",
                "reviewCount": "276"
              },
              "offers": {
                "@type": "Offer",
                "url": "${currentPageUrl || ""}",
                "availability": "http://schema.org/InStock",
                "price": "${
                  data?.variants && data.variants.length > 0
                    ? data.variants[0].sp
                    : ""
                }",
                "priceCurrency": "INR"
              }
            }
          `}
        </script>
      </Helmet>
      <div
        className="col-md-6 d-flex"
        style={{ marginTop: 15, marginBottom: 20 }}
      >
        {data?.images?.length > 0 ? (
          <div className="row singaleContainer">
            <div className="col-md-4 multiImage">
              {data?.images?.map((image) => (
                <img
                  key={image.id}
                  src={`${ImgURL}${image?.path}`}
                  alt={image.alt_text}
                  loading="lazy"
                  onError={handleImageError}
                  onMouseEnter={() => handleImageClick(image)}
                  className="selectedimage_singleproduct"
                  style={{
                    // cursor: "pointer",
                    // margin: "5px",
                    border:
                      selectedImage?.id === image?.id
                        ? "2px solid #538cee"
                        : "1px solid #ccc",
                    // borderRadius: "10px",
                    // height: 80,
                    // width: "70%",
                  }}
                />
              ))}
            </div>
            <div className="col-md-8" style={{ flex: 1 }}>
              <img
                key={selectedImage?.id}
                className="singleproductimage image-hover"
                alt={selectedImage?.alt_text}
                loading="lazy"
                onError={handleImageError}
                src={`${ImgURL}${selectedImage?.path}`}
                style={{
                  resizeMode: "cover",
                }}
              />
            </div>
          </div>
        ) : (
          <div className="col-md-6" style={{ flex: 1 }}>
            <img
              key={selectedImage?.id}
              className="singleproductimage image-hover"
              alt={data.name}
              loading="lazy"
              onError={handleImageError}
              src={`${ImgURL}${data?.img}`}
              style={{
                resizeMode: "contain",
              }}
            />
          </div>
        )}
      </div>
      <div
        className="col-md-6"
        style={{
          marginTop: 35,
          textAlign: "justify",
        }}
      >
        {data?.subcategories?.[0]?.sub_category?.category?.name &&
        data?.subcategories[0]?.sub_category?.name &&
        data?.name ? (
          <h2
            style={{
              fontSize: 14,
              marginLeft: 8,
              fontWeight: "600",
            }}
          >
            {data.subcategories[0].sub_category.category.name} /
            {data.subcategories[0].sub_category.name} /{data.name}
          </h2>
        ) : null}
        {data.name !== null && data.name !== "" ? (
          <>
            <h6 className="textColor">Name : </h6>
            <h1
              style={{
                fontSize: 18,
                marginLeft: 8,
                fontWeight: "600",
              }}
            >
              {data.name}
            </h1>
          </>
        ) : null}
        <div className="product_varient">
          {data?.variants?.map((item, index) => {
            let calculate = (item.sp / item.mrp) * 100;
            let Percentage = 100 - calculate;
            return (
              <div
                role="button"
                key={index}
                className={
                  item.id === idVariants
                    ? "item itemColor image-hover card"
                    : "item image-hover card"
                }
                onClick={() => {
                  setIdVariants(item.id);
                }}
              >
                {item.sp < item.mrp ? (
                  <div className="varient_percentage">
                    <p className="fw-bold pTag">{Percentage.toFixed(2)}% Off</p>
                  </div>
                ) : null}
                <div>
                  <p
                    className="pTag"
                    style={{ fontSize: 14, marginTop: 6, marginBottom: 0 }}
                  >
                    {item.size}
                  </p>
                </div>
                <small style={{ fontSize: 10, whiteSpace: "nowrap" }}>
                  Net Weight: {item.weight} KG
                </small>
                <div
                  className="horizontalContainer"
                  style={{
                    marginTop: 8,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {item.mrp > item.sp ? (
                    <>
                      <p
                        className="pTag"
                        style={{ fontSize: 10, marginRight: 2 }}
                      >
                        <del>₹{item.mrp}</del>
                      </p>
                      <p
                        className="pTag"
                        style={{
                          fontSize: 12,
                          marginLeft: 2,
                          fontWeight: "bold",
                        }}
                      >
                        ₹{item.sp}
                      </p>
                    </>
                  ) : (
                    <p
                      className="pTag"
                      style={{
                        fontSize: 12,
                        marginLeft: 2,
                        fontWeight: "bold",
                      }}
                    >
                      ₹{item.sp}
                    </p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <small>All above prices are inclusive of all taxes</small>
        <div style={{ marginBottom: 10, marginTop: 10 }}>
          {!isLoggedIn ? (
            <button
              type="button"
              className="btn btn-outline-success"
              onClick={() => {
                updateCart();
              }}
            >
              Add
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-outline-success"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Add
            </button>
          )}
        </div>
        {data?.short_desc ? (
          <>
            <h6 className="textColor">Short description : </h6>
            <div
              dangerouslySetInnerHTML={{ __html: data.short_desc }}
              style={{ marginTop: 20 }}
            />
          </>
        ) : null}
        {data.description ? (
          <>
            <h6 className="textColor">Product description : </h6>
            <div
              dangerouslySetInnerHTML={{ __html: data.description }}
              style={{ marginTop: 20 }}
            />
          </>
        ) : null}
        {data?.tags !== null && data?.tags !== "" ? (
          <>
            <h6 className="textColor">Tags: </h6>
            <p
              style={{ marginLeft: 10, marginRight: 10, textAlign: "justifiy" }}
            >
              {data?.tags}
            </p>
          </>
        ) : null}
        <div className="d-none">
          {data?.brand?.name !== null && data?.brand?.name !== "" ? (
            <>
              <h6 className="textColor d-none">Brand : </h6>
              <p className="d-none" style={{ marginLeft: 10 }}>
                {data?.brand?.name}
              </p>
            </>
          ) : null}
          {data.manufacturer !== null && data.manufacturer !== "" ? (
            <>
              <h6 className="textColor d-none">Manufacturer : </h6>
              <p className="d-none" style={{ marginLeft: 10 }}>
                {data.manufacturer}
              </p>
            </>
          ) : null}
        </div>
        {data.origin_country !== null && data.origin_country !== "" ? (
          <>
            <h6 className="textColor">Country of origin : </h6>
            <p style={{ marginLeft: 10 }}>{data.origin_country}</p>
          </>
        ) : null}
        <h6 className="textColor"> Manufactured and Marketed By:</h6>
        <p>
          <strong>SHRI SAI CASHEW PRIVATE LIMITED</strong>
        </p>
        <div class="company-details mb-2">
          <div class="address-section d-flex align-items-center">
            <i class="fas fa-map-marker-alt textColor"></i>
            <p className="mx-3 mb-1">
              Shop No.26, Basement and Ground Floor, Lajpat Nagar -2, South
              East, Delhi-110024
            </p>
          </div>

          <div class="fssai-section d-flex align-items-center">
            <i class="fas fa-certificate textColor"></i>
            <p className="mx-3 mb-1">
              <strong>FSSAI License Number:</strong> 13321010000449
            </p>
          </div>
        </div>

        <h6 className="textColor">
          For customer Feedback/Complaints contact our customer care at:
        </h6>
        <div class="contact-info">
          <div class="address_info d-flex align-items-center">
            <i class="fas fa-map-marker-alt textColor"></i>
            <p className="mx-3 mb-1">
              Shop No.26, Basement and Ground Floor, Lajpat Nagar -2, South
              East, Delhi-110024
            </p>
          </div>
          <div class="email d-flex align-items-center">
            <i class="fas fa-envelope textColor"></i>
            <p className="mx-3 mb-1">
              <a
                className="text-decoration-none text-dark"
                href="mailto:zayadryfruits@gmail.com"
              >
                zayadryfruits@gmail.com
              </a>
            </p>
          </div>
          <div class="phone d-flex align-items-center">
            <i class="fas fa-phone textColor"></i>
            <p className="mx-3 mb-1">
              <a
                className="text-decoration-none text-dark"
                href="tel:+919654760719"
              >
                +91 9654760719
              </a>
            </p>
          </div>
        </div>
      </div>
      <div style={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}>
        <h4 className="textColor">Related Product </h4>
      </div>
      <div className="slider-button">
        <button className="swiper-button-next" onClick={handlePrev}>
          <i className="fa-solid fa-angles-left"></i>
        </button>
        <Swiper
          onSwiper={setSwiper}
          slidesPerView={1}
          pagination={{ clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          breakpoints={{
            390: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            414: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 5,
              spaceBetween: 10,
            },
          }}
        >
          {showFeaturedProducts.map((item) => {
            let calculate =
              (item?.product?.variants[0].sp / item?.product?.variants[0].mrp) *
              100;
            let Percentage = 100 - calculate;
            let name = item?.product?.name;
            const product = name.replace(/[\s()]+/g, "-").replace(/-+$/, "");
            return (
              <div key={item.id} className="col-md-2 p-1 col-6 m-1">
                <SwiperSlide key={item.id}>
                  <Link
                    className="my-nav-link"
                    to={`/product/${item?.product?.id}/${product}`}
                  >
                    <div className="card h-100">
                      <div>
                        {item?.product?.variants[0].sp !==
                        item?.product?.variants[0].mrp ? (
                          <div
                            style={{
                              height: 40,
                              width: 40,
                              position: "absolute",
                              backgroundColor: "#538cee",
                              left: 4,
                              borderBottomRightRadius: 10,
                              borderBottomLeftRadius: 10,
                            }}
                          >
                            <p
                              className="fw-bold"
                              style={{
                                fontSize: 10,
                                color: "white",
                                textAlign: "center",
                              }}
                            >
                              {Percentage.toFixed(2)}% Off
                            </p>
                          </div>
                        ) : null}
                      </div>
                      <img
                        key={item.id}
                        alt={item?.product?.name}
                        src={`${ImgURL}${item?.product?.img}`}
                        onError={handleImageError}
                        loading="lazy"
                        className="card-img-top-name"
                        style={{
                          height: "145px",
                        }}
                      />
                      <div
                        style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          WebkitLineClamp: 2,
                          textAlign: "center",
                          paddingTop: 10,
                        }}
                      >
                        <p
                          className="fw-bold"
                          style={{ fontSize: 12, fontWeight: "500" }}
                        >
                          {/* {item.name.length > 20
                      ? item.name.substring(0, 20) + "..."
                      : item.name} */}
                          {item?.product?.name}
                        </p>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <p className="fw-bold" style={{ fontSize: 11 }}>
                          {item?.product?.variants[0].size}
                        </p>
                      </div>
                      <>
                        {item?.product?.variants[0].sp ===
                        item?.product?.variants[0].mrp ? (
                          <div
                            className="d-flex justify-content-evenly"
                            style={{ marginBottom: 10 }}
                          >
                            <div style={{ paddingTop: 10 }}>
                              <p
                                className="fw-bold"
                                style={{ fontSize: 11, margin: 0 }}
                              >
                                ₹{item?.product?.variants[0].sp}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-evenly">
                            <div>
                              <p
                                className="fw-bold"
                                style={{ fontSize: 11, margin: 0 }}
                              >
                                ₹{item?.product?.variants[0].sp}
                              </p>
                              <p style={{ fontSize: 10 }}>
                                <del>₹{item?.product?.variants[0].mrp}</del>
                              </p>
                            </div>
                          </div>
                        )}
                      </>
                    </div>
                  </Link>
                </SwiperSlide>
              </div>
            );
          })}
        </Swiper>
        <button className="swiper-button-prev" onClick={handleNext}>
          <i className="fa-solid fa-angles-right"></i>
        </button>
      </div>
    </div>
  );
};

export default SingelProducts;
