import React, { useState } from "react";
import { Link } from "react-router-dom";
import zayaBackImg from "../asset/pngImage/zayaBackImg.png";
import { ImgURL } from "../url/api";

const Categories = ({ data, onSelectSubcategory, subcategory, groceryID }) => {
  const [categoryCollapsed, setCategoryCollapsed] = useState(true);
  return (
    <div className="card width: 15rem" style={{ width: "100%" }}>
      {data.map((item) => {
        let name = item.categorie.name;
        const product = name.replace(/[\s()]+/g, "-").replace(/-+$/, "");
        return (
          <ul className="list-group list-group-flush">
            <Link
              to={`/grocery/${item.cate_id}/${product}`}
              className="Anchor"
              onClick={() => {
                setCategoryCollapsed(true);
              }}
            >
              <li
                className="list-group-item textColor"
                style={{
                  backgroundColor:
                    item.cate_id === groceryID ? "#538cee" : "#ffffff",
                }}
                onClick={() => {
                  onSelectSubcategory(item);
                }}
              >
                <img
                  src={
                    item.categorie.img == null
                      ? zayaBackImg
                      : `${ImgURL}${item.categorie.img}`
                  }
                  alt={item.categorie.name}
                  loading="lazy"
                  style={{ height: 35, width: 35 }}
                />
                <p
                  className="texthover m-1"
                  style={{
                    fontSize: 12,
                    fontWeight: "500",
                    color: item.cate_id === groceryID ? " #ffffff" : "#825d37",
                  }}
                >
                  {item.categorie.name}
                </p>
              </li>
            </Link>
          </ul>
        );
      })}
    </div>
  );
};

export default Categories;
