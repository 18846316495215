import React from "react";
import NotFound from "../asset/pngImage/notfound.png";
import Nuts from "../asset/jpgImage/kismis.jpg";
import Seeds from "../asset/jpgImage/khadaMasala.jpg";
import Masala from "../asset/jpgImage/powderMasala.jpg";
import DryFruits from "../asset/jpgImage/kaaju.jpg";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="topMargin page-not-found">
      <div className="text-center">
        <img
          src={NotFound}
          style={{ height: 300, width: 300, alignSelf: "center" }}
        />
        <h2 style={{ marginVertical: 20 }}>Page Not Found</h2>
        <p style={{ marginVertical: 20, color: "#b6b6b6" }}>
          The page you are looking for might have been removed had its name
          changed or its temporarily unavailable
        </p>
        <Link className="text-decoration-none LinkColor" to="/">
          <button type="button" className="btn btn-primary pageNotFound">
            Go To Home Page
          </button>
        </Link>
        <div className="row">
          <div className="col-md-3">
            <Link className="nav-link " to={`/subcategory/${"2"}/${"nuts"}`}>
              <img
                src={Nuts}
                loading="lazy"
                style={{
                  height: 300,
                  width: 300,
                  alignSelf: "center",
                }}
              />
            </Link>
          </div>
          <div className="col-md-3">
            <Link className="nav-link " to={`/subcategory/${"5"}/${"seeds"}`}>
              <img
                src={Seeds}
                loading="lazy"
                style={{
                  height: 300,
                  width: 300,
                  alignSelf: "center",
                }}
              />
            </Link>
          </div>
          <div className="col-md-3">
            <Link className="nav-link " to={`/subcategory/${"1"}/${"dryfruits"}`}>
              <img
                src={DryFruits}
                loading="lazy"
                style={{
                  height: 300,
                  width: 300,
                  alignSelf: "center",
                }}
              />
            </Link>
          </div>
          <div className="col-md-3">
            <Link className="nav-link " to={`/subcategory/${"6"}/${"Masale"}`}>
              <img
                src={Masala}
                loading="lazy"
                style={{
                  height: 300,
                  width: 300,
                  alignSelf: "center",
                }}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
