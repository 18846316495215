import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { URL } from "../url/api";
import ActivityIndicator from "../component/ActivityIndicator";
import { toast } from "react-toastify";

const ProfileDetails = () => {
  const [Token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  useEffect(() => {
    checkLogin();
  }, []);
  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        profileData(token);
        setToken(token);
      } else {
        console.log("isLoggedIn::::::", session);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };

  const showToast = (title) => {
    toast.success(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const profileData = (session) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${session}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}/getProfile`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const userData = JSON.parse(result);
        setLoading(false);
        setData(userData.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };
  const setProfile = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var formdata = new FormData();
    formdata.append("name", name);
    formdata.append("email", email);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(`${URL}/setProfile`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        setLoading(false);
        profileData(Token);
        const changeData = newData.msg.replace(/[\{\}\[\]]/g, "");
        showToast(changeData);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const nameInputChange = (e) => {
    setName(e.target.value);
  };
  const nameEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handaleEditeAddress = (e) => {
    setName(e.name);
    setEmail(e.email);
  };
  const handaleEditeAddressClean = (e) => {
    setName("");
    setEmail("");
  };
  if (loading) {
    return <ActivityIndicator />;
  }
  return (
    <div className="card">
      <div className="my-1 mx-2">{data?.name && `Name: ${data?.name}`}</div>
      <div className="my-1 mx-2">{data?.email && `Email: ${data?.email}`}</div>
      <div className="my-1 mx-2">
        {data?.mobile && `Mobile: ${data?.mobile}`}
      </div>
      <div className="my-1 mx-2">{data?.wallet && `Wallet: ₹${data?.wallet}`}</div>
      <i
        className="fa-solid iconClass fa-user-pen m-1"
        role="button"
        data-bs-toggle="modal"
        data-bs-target="#EditProfile"
        onClick={() => {
          handaleEditeAddress(data);
        }}
      ></i>
      <div
        className="modal fade"
        id="EditProfile"
        tabIndex="-1"
        aria-labelledby="EditProfileLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="EditProfileLabel">
                Edit Profile
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  handaleEditeAddressClean();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  aria-label="First name"
                  required
                  value={name}
                  onChange={nameInputChange}
                />
              </div>
              <div className="col my-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  aria-label="Email"
                  required
                  value={email}
                  onChange={nameEmailChange}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  handaleEditeAddressClean();
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setProfile();
                }}
                data-bs-dismiss="modal"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
