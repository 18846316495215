// const { createSlice } = require("@reduxjs/toolkit");
// const OrderIdSlice = createSlice({
//   name: "OrderIdDetails",
//   initialState: {
//     data: [],
//   },
//   reducers: {
//     OrderId(state, action) {
//       let tempData = state.data;
//       tempData.push(action.payload);
//       state.data = tempData;
//     },
//   },
// });
// export const { OrderId } = OrderIdSlice.actions;
// export default OrderIdSlice.reducer;
//  new code
const { createSlice } = require("@reduxjs/toolkit");
const OrderIdSlice = createSlice({
  name: "OrderIdDetails",
  initialState: {
    data: null,
  },
  reducers: {
    OrderId: (state, action) => {
      // Overwrite existing data with the new order details
      state.data = action.payload;
    },
  },
});
export const { OrderId } = OrderIdSlice.actions;
export default OrderIdSlice.reducer;
